const IMAGE_PATH = 'images/account/layout/'
export const USER_INFO = {
  WALLET: {
    text: 'Ví của tôi',
    icon: IMAGE_PATH + 'icon-wallet.svg'
  },
  ROLLING: {
    text: 'Cược yêu cầu',
    icon: IMAGE_PATH + 'icon-bet.svg',
    note: 'Doanh thu vòng cược cần hoàn thành trước khi rút tiền'
  }
}
export const REFUND_INFO = {
  REFUND_TOTAL: {
    title: 'TỔNG HOÀN TRẢ',
    subTitle: 'trong 30 ngày'
  },
  BONUS_TOTAL: {
    title: 'TỔNG THƯỞNG',
    subTitle: 'trong 30 ngày'
  }
}
