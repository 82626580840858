<script setup lang="ts">
import { PAGE } from '~~/constants/router'
import { BOTTOM_MENU, TMenuBottom } from '~~/resources/menuBottom'
const CommonBaseImg = defineAsyncComponent(() => import('~/components/common/base-img.vue'))

const router = useRoute()
const { toggleMenuAccount } = useToggleMenu()

const menu = computed(() => {
  const removeItem = (item: string) => {
    return item !== (router.path === PAGE.HOME ? 'trang-chu' : 'khuyen-mai')
  }
  return BOTTOM_MENU.filter((item) => removeItem(item.name))
})

const checkActive = (item: TMenuBottom) => {
  if (item.name === 'khuyen-mai' && router.path === '/') {
    return true
  }

  if (item.name === 'trang-chu' && router.path === '/khuyen-mai') {
    return true
  }

  if (router.path.includes(item.name)) {
    return true
  }
  return false
}

const clickMenuItem = (item: TMenuBottom) => {
  if (!item?.url) {
    toggleMenuAccount()
    return
  }
  navigateTo(item.url)
}
</script>

<template>
  <div class="bottom-menu">
    <div
      v-for="item in menu"
      :key="item.name"
      class="menu-item"
      :class="{ acctive: checkActive(item) }"
      @click="clickMenuItem(item)"
    >
      <CommonBaseImg class="icon" :src="item.icon" :alt="item.title" />
      <p class="title">{{ item.title }}</p>
    </div>
  </div>
</template>

<style lang="scss" scoped src="assets/scss/components/mobile/common/menu/index.scss"></style>
