import { LOBBY_GAME_URLS } from '~~/config/page-url'
import { GAME_PAGE_URL } from '~~/constants/lobby'
import { PAGE } from '~~/constants/router'

export type TMenuBottom = {
  name: string
  title: string
  url: string
  icon: string
  activeUrl: string
}

export const BOTTOM_MENU: TMenuBottom[] = [
  {
    name: 'trang-chu',
    title: 'Trang Chủ',
    url: PAGE.HOME,
    activeUrl: PAGE.HOME,
    icon: '/assets/images/icon/home-mb.svg'
  },
  {
    name: 'khuyen-mai',
    title: 'Khuyến mãi',
    url: PAGE.PROMOTION,
    activeUrl: PAGE.PROMOTION,
    icon: '/assets/images/icon/gift-mb.svg'
  },
  {
    name: 'the-thao',
    title: 'Thể Thao',
    url: PAGE.SPORTS,
    activeUrl: PAGE.SPORTS,
    icon: '/assets/images/icon/ball-mb.svg'
  },
  {
    name: 'table-game',
    title: 'Cổng Game',
    url: LOBBY_GAME_URLS.TABLE_GAME,
    activeUrl: LOBBY_GAME_URLS.TABLE_GAME,
    icon: '/assets/images/icon/cong-game-mb.svg'
  },
  {
    name: 'quay-so',
    title: 'Quay Số',
    url: LOBBY_GAME_URLS.QUAY_SO,
    activeUrl: LOBBY_GAME_URLS.QUAY_SO,
    icon: '/assets/images/icon/quay-so-mb.svg'
  },
  {
    name: 'livecasino',
    title: 'Live Casino',
    url: GAME_PAGE_URL.blackjack,
    activeUrl: GAME_PAGE_URL.blackjack,
    icon: '/assets/images/icon/live-casino-mb.svg'
  }
]
